import React from "react";
import styles from '../styles/ForFamily.module.css';
import DynamicPage from "../components/DynamicPage/DynamicPage";
import {HUNGARY} from "../assets/hungary";
import {useCookies} from "react-cookie";
import CookiesNotification from "../components/Base/Cookies/CookiesNotification";

const Hungary: React.FC = () => {
    const [cookies, setCookie] = useCookies(["cookieConsentSettings"]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.circles}/>
            <div className={styles.arc}/>
            <div className='container mx-auto'>
                <DynamicPage pageScheme={HUNGARY}/>
            </div>
            {!cookies.cookieConsentSettings && <CookiesNotification/>}
        </div>
    )
}

export default Hungary;