import React, {useState} from "react";
import {DynamicPageItemDownloadAppButtonScheme} from "../../../../types/page.interface";
import Button from "../../../Base/Button/Button";
import styles from "./DynamicDownloadAppButton.module.css";
import PromoCode from "../../../Base/PromoCode/PromoCode";
import Modal from "../../../Base/Modal/Modal";
import NoEscape from "../../../Base/NoEscape";

interface Props {
    item: DynamicPageItemDownloadAppButtonScheme;
}

const DEFAULT_TEXTS = [
    'Při registraci použijte kód <span>%code%</span> a získejte při aktivaci předplatného první měsíc zdarma.',
    'Registrace zabere jen chvilku, tak stahujte aplikaci a vyzkoušejte lékařskou péči 21. století!'
]

const DynamicDownloadAppButton: React.FC<Props> = ({item}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    function replaceKeyInString(
        string: string,
        find: string,
        replace: string
    ): string {
        return string.replace(new RegExp(find, 'g'), replace)
    }

    const texts = item.modalTexts ?? DEFAULT_TEXTS;

    return (
        <>
            <div className={styles.wrapper}>
                {item.forceModal ? <Button title={item.title} icon={item.icon} onClick={() => setIsModalOpen(true)}/> :
                    <Button title={item.title} href={item.href} icon={item.icon}/>}
                {item.showCode &&
                    <PromoCode title='Získejte při aktivaci předplatného <b>první měsíc zdarma</b>.' code={item.code}/>}
                {item.showStores && (
                    <div className={styles.stores}>
                        <img src="/images/app-store.webp" alt="AppStore"/>
                        <img src="/images/google-play.webp" alt="Google Play"/>
                    </div>
                )}
            </div>
            {isModalOpen &&
                <Modal title={item.modalTitle ?? 'Máme pro vás dárek'} titleIcon='/icons/present.svg' onClose={() => setIsModalOpen(false)}>
                    <>
                        {texts.map((text, key) => (
                            <React.Fragment key={key}><p><NoEscape>{replaceKeyInString(text, '%code%', item.code)}</NoEscape></p><br/></React.Fragment>
                        ))}
                        <div className={styles.buttonsWrapper}>
                            <Button title={item.title} href={item.href}/>
                            <PromoCode title={item.codeTitle ?? 'Při registraci použijte kód'} code={item.code} dark/>
                        </div>
                    </>
                </Modal>}
        </>
    )
}

export default DynamicDownloadAppButton;