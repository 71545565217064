import React from 'react';
import {DynamicPageItemDynamicPageDownloadAppWithRatingButtonScheme} from "../../../../types/page.interface";
import styles from "./DynamicPageDownloadAppWithRatingButton.module.css";
import DynamicDownloadAppButton from "./DynamicDownloadAppButton";
import {PageItemType} from "../../../../enums/pageItemType.enum";
import NoEscape from "../../../Base/NoEscape";

interface Props {
    item: DynamicPageItemDynamicPageDownloadAppWithRatingButtonScheme
}

const DynamicPageDownloadAppWithRatingButton: React.FC<Props> = ({item}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.frame}>
                <div className={styles.content}>
                    <div className={[styles.text, styles.leftText].join(" ")}><NoEscape>{item.textLeft}</NoEscape></div>
                    <DynamicDownloadAppButton item={{type: PageItemType.DOWNLOAD_APP_BUTTON, code: item.code, forceModal: item.forceModal, title: item.title, href: item.href, showCode: false, showStores: false, modalTitle: item.modalTitle, modalTexts: item.modalTexts, codeTitle: item.codeTitle}} />
                    <div className={styles.text}>
                        <div>
                            <img src='/icons/star-solid.svg' alt='star icon'/>
                            <img src='/icons/star-solid.svg' alt='star icon'/>
                            <img src='/icons/star-solid.svg' alt='star icon'/>
                            <img src='/icons/star-solid.svg' alt='star icon'/>
                            <img src='/icons/pul-pul-hvezda.svg' alt='half star icon'/>
                        </div>
                        <NoEscape>{item.textRight}</NoEscape>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DynamicPageDownloadAppWithRatingButton;