import {DynamicPageItemScheme} from "../types/page.interface";
import {PageItemType} from "../enums/pageItemType.enum";
import { isMobile } from "react-device-detect";

export const HUNGARY: DynamicPageItemScheme[] = [
    {
        type: PageItemType.CONTAINER,
        gap: isMobile ? 60 : 100,
        children: [
            {
                type: PageItemType.CONTAINER,
                gap: isMobile ? 40 : 100,
                children: [
                    {
                        type: PageItemType.LOGOTYPE,
                        claim: 'Rendelő határok nélkül',
                        showDownloadAppButton: isMobile
                    },
                    {
                        type: PageItemType.COLS,
                        gap: isMobile ? 40 : 160,
                        center: true,
                        children: [
                            {
                                type: PageItemType.IMAGE,
                                src: "https://cdn.meddi.com/hello/image1.png",
                                isHidden: !isMobile
                            },
                            {
                                type: PageItemType.CONTAINER,
                                gap: 30,
                                children: [
                                    {
                                        type: PageItemType.TITLE,
                                        title: 'A MEDDI-ről',
                                        subType: "H1",
                                    },
                                    {
                                        type: PageItemType.TEXT,
                                        text: 'A MEDDI alkalmazásnak köszönhetően bármikor, <span class="bold">bárhonnan kapcsolatba léphet az orvossal.</span> Egészségügyi problémákról konzultálhat üzenetküldés vagy videóhívás által, legyen szó az Ön vagy a szerettei egészségéről.  Ha az orvos indokoltnak találja, akár perceken belül megkaphatja a receptet. <span class="bold">Mindezt online intézheti, felesleges várakozás nélkül, otthona kényelméből.</span>',
                                        isHidden: isMobile
                                    },
                                    {
                                        type: PageItemType.TEXT,
                                        text: 'A MEDDI alkalmazásnak köszönhetően bármikor, <span class="bold">bárhonnan kapcsolatba léphet az orvossal.</span> Egészségügyi problémákról konzultálhat üzenetküldés vagy videóhívás által, legyen szó az Ön vagy a szerettei egészségéről.  Ha az orvos indokoltnak találja, akár perceken belül megkaphatja a receptet. <span class="bold">Mindezt online intézheti, felesleges várakozás nélkül, otthona kényelméből.</span>',
                                        isHidden: !isMobile
                                    },
                                    {
                                        type: PageItemType.DOWNLOAD_APP_BUTTON,
                                        title: "Töltse le az alkalmazást INGYEN!",
                                        href: "https://onelink.to/meddiapp",
                                        code: "HELLOHUNGARY",
                                        showStores: true,
                                        showCode: false,
                                        forceModal: true,
                                        modalTitle: 'Van egy ajándékunk az Ön számára!',
                                        modalTexts: [
                                            'Használja a <span>%code%</span> kódot a regisztráció során, és élvezze az első hónapot ingyen az előfizetés aktiválásával.',
                                            'A regisztráció mindössze egy pillanat – töltse le az alkalmazást most, és tapasztalja meg a modern, 21. századi egészségügyi ellátást!'
                                        ],
                                        codeTitle: 'Használja a kódot a regisztráció során'
                                    }
                                ]
                            },
                            {
                                type: PageItemType.IMAGE,
                                src: "https://cdn.meddi.com/hello/image1.png",
                                isHidden: isMobile
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.COLS,
                center: true,
                gap: 45,
                children: [
                    {
                        type: PageItemType.IMAGE,
                        src: isMobile ? "https://cdn.meddi.com/hello/image6.webp" : "https://cdn.meddi.com/hello/image2.webp"
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 30,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Miért érdemes a MEDDI alkalmazást választani?',
                                subType: "H2"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: '<span class="bold">A gyermekorvostól, háziorvoson át egészen szakorvosig, a MEDDI-vel mindig első osztályú egészségügyi ellátáshoz jut.</span> Spóroljon időt a várótermek elkerülésével. Az egészségügyi problémáinak egy részét intézze kényelmesen, online – számítógépen vagy okostelefonon, ahogy Önnek kényelmesebb.'
                            },
                            {
                                type: PageItemType.LIST,
                                bulletColor: 'GREEN',
                                children: [
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Egészségügyi ellátás 0-24 '
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Az egész családnak az egészségügyi problémáit egy helyen kezelheti.'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'A baktériumokkal és a vírusokkal teli orvosi várotermek mellőzése.'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Nem kell feleslegesen időt vesztegetnie a várótermekben.'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Járóbetegként korlátlan számú vizsgálat és konzultáció a szakorvossal.'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Lehetőség a mentális egészség gondozására is.'
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: 'Ingyenes MEDDI BioScan mérés havonta egyszer.'
                                    }
                                ]
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.CONTAINER,
                gap: 30,
                center: true,
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 15,
                        center: true,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Vizsgálat otthona kényelméből',
                                subType: "H4",
                            },
                            {
                                type: PageItemType.TITLE,
                                title: 'Több ezer orvos készen áll, hogy bármikor gondoskodjon az Ön családjáról.',
                                subType: "H2"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: '<span class="bold">Mondjon búcsút a zsúfolt várótermeknek!</span> Sok orvosi vizit könnyedén kiváltható egy egyszerű online kapcsolattal. Próbálja ki, hogyan működik az alkalmazásunk!'
                            }
                        ]
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 0,
                        center: true,
                        children: [
                            {
                                type: PageItemType.VIDEO,
                                src: 'https://player.vimeo.com/video/710677522?h=979f935a1e&autoplay=1',
                                placeholderSrc: 'https://cdn.meddi.com/hello/image3.webp'
                            },
                            {
                                type: PageItemType.DOWNLOAD_APP_WITH_RATING,
                                code: "HELLOHUNGARY",
                                title: "Töltse le az alkalmazást INGYEN!",
                                textLeft: 'Több mint <span class="bold">200 000 letöltés</span>',
                                textRight: '<span>95%</span>-os pozitív értékelés',
                                href: "https://onelink.to/meddiapp",
                                forceModal: true,
                                modalTitle: 'Van egy ajándékunk az Ön számára!',
                                modalTexts: [
                                    'Használja a <span>%code%</span> kódot a regisztráció során, és élvezze az első hónapot ingyen az előfizetés aktiválásával.',
                                    'A regisztráció mindössze egy pillanat – töltse le az alkalmazást most, és tapasztalja meg a modern, 21. századi egészségügyi ellátást!'
                                ],
                                codeTitle: 'Használja a kódot a regisztráció során'
                            }
                        ]
                    }
                ]
            },
            {
                type: PageItemType.COLS,
                gap: isMobile ? 60 : 160,
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 30,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Hogyan működik az alkalmazás?',
                                subType: "H3",
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: 'A MEDDI számos lehetőséget kínál arra, hogy kapcsolatba lépjen az orvosával és megossza vele az egészségügyi információit,így <span class="bold">egyszerűen kezelheti saját maga és családja egészségügyi gondjait.</span>',
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.LIST,
                                bulletColor: "BLUE",
                                theme: "DARK",
                                children: [
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "Kiterjedt orvosi adatbázisunkban gyorsan megtalálhatja az Ön számára megfelelő szakorvost bármilyen egészségügyi panasz esetén."
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "Könnyedén csatolhat fényképeket a tünetei magyarázatához, vagy videóhíváson keresztül kapcsolatba léphet orvosával."
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "Orvosa könnyedén kiállíthat recepteket online."
                                    },
                                    {
                                        type: PageItemType.LIST_ITEM,
                                        text: "A prémium szolgáltatásokat gyorsan és biztonságosan fizetheti ki néhány kattintással, egy védett fizetési felületen keresztül."
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 30,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                title: 'Mennyibe kerül az alkalmazás?',
                                subType: "H3",
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.TEXT,
                                text: 'A MEDDI alkalmazással évente egyszer ingyenesen kapcsolatba léphet az orvosával. Ezt követően a szolgáltatás az aktuális árlista alapján vehető igénybe. <span class="bold">Lehetősége van kényelmes előfizetési csomagot választani saját maga vagy akár az egész családja számára.</span>',
                                theme: "DARK"
                            },
                            {
                                type: PageItemType.IMAGE,
                                src: "https://cdn.meddi.com/hello/image7.webp"
                            }
                        ]
                    }
                ]
            },
            {
                type: PageItemType.COLOR_BOX,
                backgroundImageSrc: "",
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 40,
                        center: true,
                        children: [
                            {
                                type: PageItemType.CONTAINER,
                                gap: 15,
                                center: true,
                                children: [
                                    {
                                        type: PageItemType.TITLE,
                                        title: 'Próbálja ki a MEDDI-t!',
                                        subType: "H2",
                                    },
                                    {
                                        type: PageItemType.TEXT,
                                        text: 'Az alkalmazás telepítése és beállítása mindössze néhány percet vesz igénybe.<br/> <span class="bold">Kerülje el a várótermeket, és töltsön több időt a családjával.</span>'
                                    },
                                ]
                            },
                            {
                                type: PageItemType.DOWNLOAD_APP_BUTTON,
                                title: "Töltse le az alkalmazást INGYEN!",
                                href: "https://onelink.to/meddiapp",
                                code: "HELLOHUNGARY",
                                forceModal: true,
                                modalTitle: 'Van egy ajándékunk az Ön számára!',
                                modalTexts: [
                                    'Használja a <span>%code%</span> kódot a regisztráció során, és élvezze az első hónapot ingyen az előfizetés aktiválásával.',
                                    'A regisztráció mindössze egy pillanat – töltse le az alkalmazást most, és tapasztalja meg a modern, 21. századi egészségügyi ellátást!'
                                ],
                                codeTitle: 'Használja a kódot a regisztráció során'
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.CONTAINER,
                gap: 40,
                children: [
                    {
                        type: PageItemType.CONTAINER,
                        gap: 0,
                        center: true,
                        children: [
                            {
                                type: PageItemType.TITLE,
                                subType: "H2",
                                title: "Gyakran Ismételt kérdések a MEDDI alkalmazásról",
                                theme: "DARK"
                            }
                        ]
                    },
                    {
                        type: PageItemType.CONTAINER,
                        gap: 0,
                        children: [
                            {
                                type: PageItemType.FAQ_LIST,
                                children: [
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Hogyan adhatom hozzá a gyermekemet a fiókomhoz?",
                                        text: 'A gyermek hozzáadásához lépjen a „Saját profil” fülre, majd válassza a „Család” opciót. Itt megtalálja a meglévő profilok áttekintését, valamint lehetőséget új profil hozzáadására. Fontos, hogy minden szükséges adatot pontosan töltsön ki, még a gyermek esetében is. Csak így tud az orvos pontos diagnózist felállítani a telemedicina alkalmazás használata során.'
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Miért szükséges megadni a születési dátumot?",
                                        text: "A születési dátum megadása az Ön pontos azonosításához és az egészségügyi szolgáltatások zökkenőmentes biztosításához szükséges. Ez lehetővé teszi, hogy az orvosok hozzáférjenek a szükséges adatokhoz, kiállítsák a megfelelő recepteket, és biztonságosan kezeljék egészségügyi problémáit."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Mennyi időt vesz igénybe a kérésem feldolgozása?",
                                        text: "A válaszidő az orvos leterheltségétől és az egyes esetek sürgősségétől függ, hasonlóan a személyes vizitekhez. Az alkalmazás értesíti az orvost, amikor Ön segítséget kér. 2021-ben az átlagos várakozási idő mindössze 6 perc volt."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Honnan tudom, hogy a kérésemet feldolgozták és eljutott az orvoshoz?",
                                        text: "Amint elküldi a kérését, az alkalmazás értesíti Önt, amikor az orvos beengedi a virtuális váróterembe."
                                    },
                                    {
                                        type: PageItemType.FAQ_LIST_ITEM,
                                        title: "Használhatom a MEDDI alkalmazás szolgáltatásait regisztráció nélkül?",
                                        text: "A teljes regisztráció hiányában a szolgáltatások teljes körű működése nem garantálható."
                                    },
                                ]
                            },
                            {
                                type: PageItemType.DOWNLOAD_APP_WITH_RATING,
                                code: "HELLOHUNGARY",
                                title: "Töltse le az alkalmazást INGYEN!",
                                textLeft: 'Több mint <span class="bold">200 000 letöltés</span>',
                                textRight: '<span>95%</span>-os pozitív értékelés',
                                href: "https://onelink.to/meddiapp",
                                forceModal: true,
                                modalTitle: 'Van egy ajándékunk az Ön számára!',
                                modalTexts: [
                                    'Használja a <span>%code%</span> kódot a regisztráció során, és élvezze az első hónapot ingyen az előfizetés aktiválásával.',
                                    'A regisztráció mindössze egy pillanat – töltse le az alkalmazást most, és tapasztalja meg a modern, 21. századi egészségügyi ellátást!'
                                ],
                                codeTitle: 'Használja a kódot a regisztráció során'
                            }
                        ]
                    },
                ]
            },
            {
                type: PageItemType.FOOTER,
                logotypeClaim: 'Rendelő határok nélkül',
                textRight: 'További információ itt található <br/><a href="https://www.meddi.com" target="_blank">www.meddi.com</a>'
            }
        ]
    }
]